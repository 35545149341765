<template>
  <div>
    <div id="crm-field-label-div-202102090218"
         class="d-flex align-items-center"
    >
      <i id="crm-field-icon-202102090218"
         v-if="!!getIconForCrmObject"
         class="mr-2"
         :class="getIconForCrmObject"
      ></i>

      <component
        id="crm-field-logo-202102090218"
        :is="getCrmLogo(crmObject.service_key)"
        class="crm-logo crm-logo__slate"
      />
    </div>
    <div v-for="(fieldDefinition, index) in fieldDefinitions"
         :key="index"
    >
      <CrmField
        :item="playbookItem"
        :item-id="itemId"
        :field-definition="fieldDefinition"
        :clear-crm-data-count="clearCrmDataCount"
        :download-crm-data-count="downloadCrmDataCount"
        :is-open-from-call-wrap-up="isOpenFromCallWrapUp"
        @input="value=>handleInputAnswers(fieldDefinition.id, index, value)"
        @set-internal-value="value=>setFieldValues(fieldDefinition.id, index, value)"
      ></CrmField>
    </div>
  </div>

</template>

<script>
import CrmField from "@/apps/call/CallViewItem_components/CrmField"
import counterpartAppInfo from "@/apps/counterpart"
import CrmLogoMixin from "@/apps/call_history/CallSummaryComponents/CrmLogoMixin"

export default {
  name: "CrmFieldList",
  mixins: [CrmLogoMixin],
  components: { CrmField },
  props: {
    playbookItem: {
      type: Object,
      required: true
    },
    itemId: {
      type: [String, Number],
      default: "",
      required: false
    },
    clearCrmDataCount: {
      type: Number,
      default: 0
    },
    downloadCrmDataCount: {
      type: Number,
      default: 0
    },
    isOpenFromCallWrapUp: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fieldDefinitions () {
      return this.playbookItem.linked_field.field_definition
    },
    getIconForCrmObject () {
      if (!!this.playbookItem && !!this.crmObject && !!this.crmObject.object_type) {
        return counterpartAppInfo.getCounterpartIcon(this.crmObject.object_type)
      }
      return ""
    },
    crmObject () {
      return this.playbookItem.linked_field.crm_object_link
    }
  },
  methods: {
    handleInputAnswers (questionId, index, answers) {
      this.setFieldValues(questionId, index, answers)
      this.handleAnswersChange()
    },
    setFieldValues (questionId, index, answers) {
      answers.filter(answer => answer).forEach(answer => {
        answer.question_id = questionId
      })
      this.$set(this.fieldDefinitions[index], "selectedAnswers", answers)
    },
    handleAnswersChange () {
      const answers = this.fieldDefinitions
        .filter(item => item.selectedAnswers && item.selectedAnswers.length)
        .map(item => item.selectedAnswers)
        .flat()
      this.$emit("input", answers)
    }
  }
}
</script>

<style scoped lang="scss">

.crm-logo {
  width: 20px;
  height: 20px;
}

</style>
